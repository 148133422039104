import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import './ServiceOverview.css';
import 'chart.js';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function ServiceOverview() {
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { page } = useParams();
  const navigate = useNavigate();
  const currentPage = page ? parseInt(page, 10) : 1;

  const fetchReportsForService = (service) => {
    return axios.get(`https://disapi.armann-systems.com/reports`, { params: { dienstTitel: service.Titel }})
      .then(response => {
        const reportCounts = new Array(6).fill(0);
        const labels = new Array(6).fill().map((_, idx) => {
          const hour = new Date().getHours() - idx;
          return hour < 0 ? hour + 24 : hour;
        }).reverse();

        response.data.forEach(report => {
          const reportHour = new Date(report.Timestamp).getHours();
          const hourIndex = labels.indexOf(reportHour);
          if (hourIndex !== -1) {
            reportCounts[hourIndex]++;
          }
        });

        return {
          ...service,
          chartData: {
            labels: labels.map(label => `${label}:00`),
            datasets: [{
              label: 'Anzahl der Störungen',
              data: reportCounts,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }]
          }
        };
      });
  };

  useEffect(() => {
    const fetchServices = () => {
      const endpoint = searchTerm ? 'search-services' : 'services';
      const params = searchTerm ? { term: searchTerm } : { page: currentPage };

      axios.get(`https://disapi.armann-systems.com/${endpoint}`, { params })
        .then(response => {
          return Promise.all(response.data.map(service => fetchReportsForService(service)));
        })
        .then(servicesWithReports => {
          setServices(servicesWithReports);
        })
        .catch(error => console.error('Fehler:', error));
    };

    fetchServices();
  }, [searchTerm, currentPage]);

  const goToPage = (newPage) => {
    navigate(`/services/page/${newPage}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true, // Startet die Y-Achse bei 0
        min: 0, // Keine negativen Werte zulassen
        ticks: {
          stepSize: 1, // Stellt sicher, dass nur Ganzzahlen verwendet werden
          callback: function(value) {
            if (Number.isInteger(value)) {
              return value; // Zeigt nur Ganzzahlen an
            }
          }
        }
      }
    }
  };
  
  return (
    <div className="service-overview">
      <Helmet>
        <title>Störungsübersicht aller Services - Armann Systems</title>
        <meta name="description" content="Überblick über die IT-Services und aktuelle Störungsmeldungen" />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Armann Systems GmbH" />
      </Helmet>
      <div className="search-container">
        <input
          type="text"
          placeholder="Dienste suchen..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
      </div>
      <div className="service-list">
        {services.map(service => (
          <Link to={`/services/${service.Titel}`} key={service.DienstID} className="service-link">
            <div className="service-item">
              <img 
                src={`https://img.ashub.de/services/${service.DienstName}.png`} 
                alt={service.Titel} 
                className="service-logo"
                onError={(e) => { e.target.onerror = null; e.target.src = 'https://img.ashub.de/services/404.png'; }} // Fallback für Standardbild
              />
              <div className="service-info">
                <h3>{service.Titel}</h3>
                <p>{service.description ? service.description.substring(0, 100) + '...' : 'Keine Beschreibung verfügbar'}</p>
                <div className="chart-container">
                  <Line data={service.chartData} options={chartOptions} />
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="pagination">
        <button onClick={() => goToPage(Math.max(currentPage - 1, 1))} disabled={currentPage === 1}>
          Vorherige
        </button>
        <span>Seite {currentPage}</span>
        <button onClick={() => goToPage(currentPage + 1)}>
          Nächste
        </button>
      </div>
    </div>
  );
}

export default ServiceOverview;
