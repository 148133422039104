import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import LandingPage from './LandingPage';
import ContactPage from './ContactPage'; // Kontaktseite Komponente
import ImprintPage from './ImprintPage'; // Impressumseite Komponente
import NotFoundPage from './NotFoundPage';
import ServiceOverview from './ServiceOverview';
import Datenschutz from './Datenschutz';
import IssuesPage from './IssuesPage';
import FAQPage from './FAQPage';
import Logo from './logo100x55.png'; // Pfad zu Ihrem Logo
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import './App.css'; // Import the CSS file

function App() {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  const handleAccept = () => {
    setAcceptedCookies(true);
  };

  return (
    <Router>
      <div>
        <Helmet>
          {/*<title>//A Störungen</title>
          <link rel="icon" type="image/png" href="https://armann-systems.com/wp-content/uploads/2020/02/armannsystems-logo-77x44-1.png" sizes="16x16" />
  */}
          {/* Google Tag Manager */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-5PQ39Q8S7W"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-5PQ39Q8S7W');
            `}
          </script>
          {/* End Google Tag Manager */}
        </Helmet>
        <nav className="navbar">
          <Link to="/" className="logoContainer">
            <img src={Logo} alt="Logo" className="logo" />
          </Link>
          <div className="navLinks">
            <Link to="/" className="navItem">Startseite</Link>
            <Link to="/services" className="navItem">Dienste</Link>
            <Link to="/faq" className="navItem">FAQ</Link>
            <Link to="/contact" className="navLegal">Kontakt</Link>
            <Link to="/imprint" className="navLegal">Impressum</Link>
            <Link to="/datenschutz" className="navLegal">Datenschutz</Link>
          </div>
        </nav>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path='/services' element={<ServiceOverview />} />
          <Route path='/services/page/:page' element={<ServiceOverview />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/imprint" element={<ImprintPage />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/services/:dienstName" element={<IssuesPage />} />
          <Route path="/faq" element={<FAQPage />} />
        </Routes>

        {!acceptedCookies && (
          <CookieConsent
            location="bottom"
            buttonText="Akzeptieren"
            cookieName="cookieConsent"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            onAccept={handleAccept}
          >
            Durch die Nutzung dieser Website stimmen Sie unserer Datenschutzerklärung und der Verwendung von Cookies zur Sammlung von personenbezogenen Daten zu.
          </CookieConsent>
        )}
      </div>
      <footer className="footer">
        <p>&copy; 2024 Armann Systems GmbH. Alle Rechte vorbehalten.</p>
      </footer>
    </Router>
  );
}

export default App;
