import React from 'react';
import ReactDOM from 'react-dom';
import './frontend/index.css'; // Pfade angepasst
import App from './frontend/App'; // Pfade angepasst
import reportWebVitals from './frontend/reportWebVitals'; // Pfade angepasst

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Wenn du die Web-Vitals messen möchtest, passe auch den Pfad hier entsprechend an
reportWebVitals();
