import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';


const faqsCategorized = {
    "Internetprobleme": [
      { question: "Was sind die ersten Schritte zur Fehlerbehebung bei einer Internetstörung?", answer: "Wenn Sie auf eine Internetstörung stoßen, überprüfen Sie zuerst, ob das Problem auf allen Geräten besteht. Starten Sie dann Ihren Router und Modem neu. Überprüfen Sie auch, ob Ihr Internetanbieter über bekannte Probleme informiert ist." },
      { question: "Wie kann ich feststellen, ob eine Störung in meinem Netzwerk oder Gerät liegt?", answer: "Um festzustellen, ob eine Störung in Ihrem Netzwerk oder Gerät vorliegt, versuchen Sie, mit einem anderen Gerät auf das Netzwerk zuzugreifen. Funktioniert es bei einem Gerät, liegt das Problem wahrscheinlich am anderen Gerät." },
      { question: "Was sind die ersten Schritte zur Diagnose eines langsamen Internetzugangs?", answer: "Wenn Ihr Internetzugang langsam ist, testen Sie zuerst die Geschwindigkeit mit einem Online-Speedtest. Schließen Sie unnötige Anwendungen und überprüfen Sie, ob mehrere Geräte gleichzeitig das Netzwerk belasten." },
      { question: "Wie gehe ich vor, wenn mein WLAN-Netzwerk nicht gefunden wird?", answer: "Wenn Ihr WLAN-Netzwerk nicht gefunden wird, stellen Sie sicher, dass Ihr WLAN-Router eingeschaltet ist und richtig funktioniert. Starten Sie den Router und Ihr Gerät neu und prüfen Sie die WLAN-Einstellungen." },
      { question: "Wie kann ich die Qualität meines WLAN-Signals verbessern?", answer: "Um die Qualität Ihres WLAN-Signals zu verbessern, positionieren Sie den Router an einem zentralen Ort, frei von Hindernissen. Verwenden Sie WLAN-Verstärker oder Powerline-Adapter, um die Reichweite zu erweitern." },
      { question: "Wie kann ich meine Internetgeschwindigkeit maximieren?", answer: "Um Ihre Internetgeschwindigkeit zu maximieren, überprüfen Sie, ob Ihr Internetanbieter einen schnelleren Tarif anbietet. Aktualisieren Sie Ihre Router-Firmware und verwenden Sie Ethernet-Kabel statt WLAN, wenn möglich." },
      {
        question: "Was tun bei häufigen Unterbrechungen der Internetverbindung?",
        answer: "Bei häufigen Internetunterbrechungen überprüfen Sie, ob das Problem bei Ihrem ISP liegt. Nutzen Sie Netzwerkanalyse-Tools, um die Verbindungsstabilität zu überwachen. Kontaktieren Sie den Kundenservice Ihres Anbieters bei anhaltenden Problemen."
      },
      {
        question: "Wie setze ich meinen Router auf die Werkseinstellungen zurück?",
        answer: "Um Ihren Router zurückzusetzen, drücken Sie die Reset-Taste für einige Sekunden. Dies setzt alle Einstellungen auf Werkseinstellungen zurück. Beachten Sie, dass Sie danach Netzwerkeinstellungen neu konfigurieren müssen."
      },
      {
        question: "Welche Tipps gibt es für die Einrichtung eines sicheren Heimnetzwerks?",
        answer: "Für ein sicheres Heimnetzwerk verwenden Sie starke, einzigartige Passwörter, aktivieren Sie die Netzwerkverschlüsselung und halten Sie die Router-Firmware aktuell. Vermeiden Sie Standard-SSID-Namen und -Passwörter."
      },
      {
        question: "Wie löse ich Verbindungsprobleme bei spezifischen Webseiten?",
        answer: "Wenn bestimmte Webseiten nicht laden, löschen Sie zunächst Ihren Browser-Cache und Cookies. Überprüfen Sie dann Ihre DNS-Einstellungen und stellen Sie sicher, dass keine spezifischen Firewall-Einstellungen den Zugriff blockieren."
      },
      {
        question: "Was bedeutet eine 'IP-Konflikt'-Fehlermeldung und wie behebe ich sie?",
        answer: "Ein IP-Konflikt tritt auf, wenn zwei Geräte im selben Netzwerk dieselbe IP-Adresse verwenden. Beheben Sie dies, indem Sie Ihrem Gerät eine feste IP zuweisen oder Ihren Router neu starten, um die IP-Adressen neu zuzuweisen."
      },
    ],
    "Geräteprobleme": [
      { question: "Was kann ich tun, wenn mein Smartphone keine Verbindung zum Mobilfunknetz herstellt?", answer: "Wenn Ihr Smartphone keine Verbindung zum Mobilfunknetz herstellt, überprüfen Sie zuerst, ob Sie sich in einem Gebiet mit schlechter Netzabdeckung befinden. Schalten Sie Ihr Smartphone in den Flugmodus und dann wieder zurück, um die Netzwerksuche zu erneuern." },
      { question: "Wie gehe ich vor, wenn mein Computer oder Laptop nicht startet?", answer: "Wenn Ihr Computer oder Laptop nicht startet, überprüfen Sie zuerst die Stromversorgung und alle Anschlüsse. Versuchen Sie dann, den Computer im abgesicherten Modus zu starten, um festzustellen, ob es sich um ein Softwareproblem handelt." },
      { question: "Was sollte ich tun, wenn mein Fernsehgerät kein Signal empfängt?", answer: "Wenn Ihr Fernsehgerät kein Signal empfängt, überprüfen Sie zuerst alle Kabelverbindungen und stellen Sie sicher, dass Ihr TV auf den richtigen Eingang eingestellt ist. Versuchen Sie einen Neustart des Fernsehers und des Receivers." },
      { question: "Was kann ich tun, wenn mein Drucker nicht reagiert?", answer: "Wenn Ihr Drucker nicht reagiert, stellen Sie sicher, dass der Drucker eingeschaltet und mit dem Netzwerk oder Computer verbunden ist. Überprüfen Sie auch die Druckerwarteschlange und starten Sie den Drucker neu." },
      {
        question: "Wie behebe ich Bluetooth-Verbindungsprobleme auf meinem Gerät?",
        answer: "Bei Bluetooth-Problemen überprüfen Sie, ob Bluetooth auf beiden Geräten aktiviert ist und die Geräte sich in Reichweite befinden. Starten Sie dann Bluetooth oder die Geräte neu. Stellen Sie sicher, dass keine anderen Interferenzen vorliegen."
      },
    ],
    "Software & Anwendungen": [
      { question: "Was macht störung.armann-systems.com anders als andere Störungsmeldungsportale?", answer: "Unsere Plattform sticht durch eine nutzerbasierte Echtzeit-Übersicht über Störungen heraus. Wir kombinieren aktuelle, von Nutzern bereitgestellte Störungsinformationen mit unserer robusten Datenanalyse, um Trends und wiederkehrende Probleme zu identifizieren." },
      { question: "Gibt es eine mobile App für störung.armann-systems.com, um Störungen unterwegs zu melden und zu überprüfen?", answer: "Derzeit ist störung.armann-systems.com als Web-Plattform verfügbar, die auch auf mobilen Geräten funktioniert. Eine dedizierte App ist derzeit nicht geplant." },
      { question: "Wie kann ich mich aktiv an der Gemeinschaft von störung.armann-systems.com beteiligen?", answer: "Sie können sich aktiv beteiligen, indem Sie Störungen melden, Kommentare zu Störungsmeldungen hinzufügen und Ihre Erfahrungen teilen. Ihre Beiträge helfen anderen Nutzern, besser informiert zu sein und Probleme effizienter zu lösen." },
      { question: "Gibt es eine Möglichkeit, auf störung.armann-systems.com Benachrichtigungen für bestimmte Dienste oder Regionen zu erhalten?", answer: "Wir arbeiten an der Implementierung einer Benachrichtigungsfunktion, damit Nutzer individuelle Alerts für bestimmte Dienste oder Regionen erhalten können. Bleiben Sie auf dem Laufenden für zukünftige Verbesserungen." },
      { question: "Wie gewährleistet störung.armann-systems.com die Genauigkeit der Störungsmeldungen?", answer: "Alle Störungsmeldungen werden durch unser System überprüft, um die Genauigkeit zu gewährleisten. Wir nutzen auch automatisierte Verfahren, um Muster und Trends zu identifizieren, und verlassen uns auf die Gemeinschaft, um die Daten zu validieren." },
      {
        question: "Wie aktualisiere ich meine Software sicher und effektiv?",
        answer: "Aktualisieren Sie Ihre Software regelmäßig durch das offizielle Updatetool oder den App Store. Stellen Sie sicher, dass Ihre Systemeinstellungen für automatische Updates eingestellt sind und überprüfen Sie manuell auf Updates, um Sicherheitslücken zu vermeiden."
      },
    ],
    "Sicherheit & Datenschutz": [
      { question: "Wie kann ich sicherstellen, dass meine persönlichen Daten bei einer Störung sicher sind?", answer: "Um sicherzustellen, dass Ihre persönlichen Daten bei einer Störung sicher sind, sollten Sie regelmäßige Backups durchführen. Verwenden Sie starke Passwörter und halten Sie Ihre Software stets auf dem neuesten Stand, um Sicherheitslücken zu vermeiden." },
      { question: "Welche Maßnahmen kann ich ergreifen, um mich vor Online-Bedrohungen zu schützen?", answer: "Um sich vor Online-Bedrohungen zu schützen, installieren Sie eine zuverlässige Antivirensoftware und halten Sie sie stets aktualisiert. Seien Sie vorsichtig beim Öffnen von E-Mail-Anhängen und besuchen Sie nur vertrauenswürdige Websites." },
      { question: "Wie kann ich die Privatsphäre meiner Internetverbindung gewährleisten?", answer: "Um die Privatsphäre Ihrer Internetverbindung zu gewährleisten, verwenden Sie Virtual Private Networks (VPNs), um Ihre Online-Aktivitäten zu verschlüsseln und Ihre IP-Adresse zu verbergen. Deaktivieren Sie auch die automatische Verbindung zu öffentlichen WLAN-Netzwerken." },
      { question: "Welche Vorteile bietet die Verwendung von Cloud-Speicherlösungen?", answer: "Cloud-Speicher ermöglicht den einfachen Zugriff auf Dateien von verschiedenen Geräten aus und bietet Backup-Möglichkeiten, um Datenverlust zu verhindern. Außerdem spart es Speicherplatz auf lokalen Geräten." },
      {
        question: "Was sind die besten Praktiken, um meine Online-Identität zu schützen?",
        answer: "Schützen Sie Ihre Online-Identität, indem Sie starke Passwörter verwenden und regelmäßig ändern, Zwei-Faktor-Authentifizierung aktivieren und persönliche Informationen nur auf vertrauenswürdigen Seiten teilen. Seien Sie vorsichtig bei öffentlichen Wi-Fi-Netzwerken."
      },
      // Neue Themen hinzugefügt
    ],
      "Datensicherung & Wiederherstellung": [
        { question: "Wie kann ich meine Daten effektiv sichern und wiederherstellen?", answer: "Es gibt verschiedene Methoden zur effektiven Sicherung und Wiederherstellung von Daten, darunter regelmäßige manuelle Backups auf externen Festplatten, die Verwendung von Cloud-Backup-Diensten und die Nutzung von dedizierter Backup-Software." },
        { question: "Was sollte ich bei der Auswahl einer Backup-Lösung beachten?", answer: "Beim Auswahl einer Backup-Lösung ist es wichtig, auf Faktoren wie Sicherheit, Zuverlässigkeit, Benutzerfreundlichkeit und Kompatibilität mit Ihren Geräten zu achten. Vergleichen Sie verschiedene Optionen, bevor Sie sich entscheiden." },
        { question: "Wie kann ich sicherstellen, dass meine Backup-Daten geschützt sind?", answer: "Um sicherzustellen, dass Ihre Backup-Daten geschützt sind, verschlüsseln Sie diese vor der Übertragung und speichern Sie sie an einem sicheren Ort, der vor physischem und digitalem Diebstahl geschützt ist. Verwenden Sie auch starke Passwörter und aktualisieren Sie diese regelmäßig." },
        { question: "Was sind bewährte Methoden für die Wiederherstellung von Daten aus Backups?", answer: "Bei der Wiederherstellung von Daten aus Backups sollten Sie sicherstellen, dass Sie über die erforderlichen Tools und Anleitungen verfügen. Gehen Sie systematisch vor und überprüfen Sie die Integrität der wiederhergestellten Daten, um sicherzustellen, dass sie vollständig und korrekt sind." },
        {
            question: "Wie wichtig ist die regelmäßige Aktualisierung von Sicherungssoftware?",
            answer: "Regelmäßige Aktualisierungen von Sicherungssoftware sind entscheidend für die Datensicherheit. Sie stellen sicher, dass Ihre Software mit den neuesten Sicherheitspatches und Funktionen ausgestattet ist, um Daten effektiv zu schützen."
        },
        {
            question: "Welche Rolle spielt die Cloud-Verschlüsselung bei der Datensicherung?",
            answer: "Cloud-Verschlüsselung spielt eine wichtige Rolle bei der Datensicherung, indem sie Ihre Daten vor unbefugtem Zugriff schützt. Wählen Sie einen Cloud-Dienst, der starke Verschlüsselungsmethoden für die Speicherung und Übertragung von Daten bietet."
        },
        
      ]
      // Weitere Themen können hier hinzugefügt werden, um die SEO-Rankings zu verbessern
    };

function FAQPage() {
    const [activeCategory, setActiveCategory] = useState(Object.keys(faqsCategorized)[0]);
    const [openFAQIndex, setOpenFAQIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFaqs, setFilteredFaqs] = useState(faqsCategorized[activeCategory]);
  
  const toggleFAQ = (rowIndex, colIndex) => {
    setOpenFAQIndex(prevIndex => {
      if (prevIndex === `${rowIndex}-${colIndex}`) {
        return null;
      } else {
        return `${rowIndex}-${colIndex}`;
      }
    });
  };

  useEffect(() => {
    const filtered = faqsCategorized[activeCategory].filter(faq =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredFaqs(filtered);
  }, [searchTerm, activeCategory]);

  const toggleCategory = category => {
    setActiveCategory(category);
    setOpenFAQIndex(null); // Setze den Zustand für die offene FAQ zurück
  };

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqsCategorized[activeCategory].map((faq, index) => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer
      }
    }))
  };

  return (
    <div style={{padding: '40px', background: '#f0f4f8'}}>
      <Helmet>
        <title>Fragen und Antworten</title>
        <meta name="description" content="Finden Sie Antworten auf häufig gestellte Fragen bei störung.armann-systems.com. Schnelle Hilfe und praktische Tipps zu Störungsmeldungen und -behebung." />
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Armann Systems GmbH" />
      </Helmet>
      <div style={{textAlign: 'center', marginBottom: '50px', color: '#333', fontFamily: "'Helvetica Neue', sans-serif"}}>
        <h1 style={{fontSize: '2.5rem'}}>Häufig gestellte Fragen</h1>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{
    display: 'flex', // Flex-Container
    flexWrap: 'wrap', // Ermöglicht das Umfließen der Buttons
    justifyContent: 'center', // Zentriert die Buttons
    marginBottom: '20px'
}}>
    {Object.keys(faqsCategorized).map(category => (
        <button
            key={category}
            style={{
                margin: '5px', // Geringerer Rand für eine bessere Anordnung
                padding: '10px 20px',
                background: activeCategory === category ? '#3498db' : '#ddd',
                color: activeCategory === category ? '#fff' : '#333',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                outline: 'none',
                fontSize: '16px', // Leicht größere Schriftgröße für bessere Lesbarkeit
            }}
            onClick={() => toggleCategory(category)}
        >
            {category}
        </button>
    ))}
</div>

    </div>
    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
      <input
        type="text"
        placeholder="Frage suchen..."
        style={{
          padding: '10px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          width: '100%',
          maxWidth: '500px'
        }}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
    </div>
    <div>
      {filteredFaqs.map((faq, index) => (
        <div
          key={index}
          style={{
            background: '#fff', 
            borderRadius: '15px', 
            padding: '15px', 
            boxShadow: '0 8px 16px rgba(0,0,0,0.15)', 
            cursor: 'pointer', 
            marginBottom: '10px', 
            position: 'relative', 
            overflow: 'hidden', 
            transition: 'all 0.3s ease'
          }}
          onClick={() => toggleFAQ(activeCategory, index)}
        >
          <div style={{fontWeight: 'bold', fontSize: '1.2rem', color: '#34495e'}}>{faq.question}</div>
          <div style={{
            display: openFAQIndex === `${activeCategory}-${index}` ? 'block' : 'none', 
            color: '#2c3e50', 
            lineHeight: '1.6', 
            paddingTop: '10px'
          }}>
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  </div>
);
}

export default FAQPage;
