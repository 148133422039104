import React from 'react';
import { Helmet } from 'react-helmet';

function ImprintPage() {
  return (
    <div style={styles.container}>
           <Helmet>
      <title>Impressum</title>
      <meta name="description" content="Betreiber der Störungswebsite störung.armann-systems.com" />
      <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Armann Systems GmbH" />
    </Helmet>
      <h1 style={styles.heading}>Impressum</h1>
      <div style={styles.content}>
        <p><strong>Verantwortlich</strong></p>
        <p>Armann Systems GmbH<br />
           Tulpenweg 1<br />
           93092 Barbing
        </p>

        <p><strong>Sitz der Gesellschaft:</strong> Barbing<br />
           <strong>Amtsgericht:</strong> Regensburg<br />
           HRB 17396<br />
           USt-IdNr.: DE326370558
        </p>

        <p><strong>Geschäftsführer</strong><br />
           Markus Armann<br />
           Geschäftsführender Gesellschafter
        </p>

        <p><strong>Kontakt</strong><br />
           Telefon: +49 9401 91791 0 | Fax -111<br />
           E-Mail: <a href="mailto:kontakt@armann-systems.com" style={styles.link}>kontakt@armann-systems.com</a>
        </p>

        <p><strong>Geschäftszeiten</strong><br />
           Montag bis Freitag 08:00 bis 17:00 Uhr
        </p>

        <p><strong>Konzeption, Realisierung und Betrieb</strong><br />
           Armann Systems GmbH<br />
           Tulpenweg 1<br />
           93092 Barbing
        </p>

        <p style={styles.paragraph}>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.

Als Diensteanbieter sind wir gemäß § 5 TMG / § 55 Abs. 2 RStV für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei bekannt werden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
        
        <p><strong>Bildquellen</strong><br />
           Die auf unserer Seite verwendeten Bildmaterialien stammen von pixabay (pixabay.com), von unseren Partnern und von uns selbst. Für alle verwendeten Bilder und Grafiken wurden die benötigten Lizenzen erworben.
        </p>
      </div>
    </div>
  );
}

const styles = {
    container: {
      padding: '20px',
      backgroundColor: 'white',
      color: 'black',
      textAlign: 'center',
    },
    heading: {
      marginBottom: '20px',
    },
    content: {
      lineHeight: '1.6',
      textAlign: 'justify',
      maxWidth: '800px', // Max. Breite des Textblocks
      margin: '0 auto', // Zentriert den Textblock
    },
    paragraph: {
      marginBottom: '15px',
    },
  };
  
  export default ImprintPage;