import React, { useState } from 'react';
import { Helmet } from 'react-helmet';


function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [notification, setNotification] = useState({ show: false, message: '', success: false });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailContent = `Name: ${formData.name}\nEmail: ${formData.email}\nNachricht: ${formData.message}`;

    fetch('https://disapi.armann-systems.com/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: emailContent }),
    })
    .then(response => {
      if (response.ok) {
        setNotification({ show: true, message: 'Nachricht erfolgreich gesendet', success: true });
        setFormData({ name: '', email: '', message: '' }); // Formular zurücksetzen
      } else {
        throw new Error('Senden fehlgeschlagen');
      }
    })
    .catch(error => {
      console.error('Fehler:', error);
      setNotification({ show: true, message: 'Senden fehlgeschlagen', success: false });
    });
  };

  return (
    <div style={styles.container}>
      <Helmet>
      <title>Kontaktformular</title>
      <meta name="description" content="Kontaktformular für Verbesserungsvorschläge oder Probleme" />
      <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Armann Systems GmbH" />
    </Helmet>
      <h1 style={styles.heading}>Kontaktieren Sie uns</h1>
      <form style={styles.form} onSubmit={handleSubmit}>
        <input 
          type="text" 
          placeholder="Ihr Name" 
          style={styles.input} 
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
        <input 
          type="email" 
          placeholder="Ihre E-Mail" 
          style={styles.input} 
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
        <textarea 
          placeholder="Ihre Nachricht" 
          style={styles.textarea} 
          name="message"
          value={formData.message}
          onChange={handleInputChange}
        ></textarea>
        <button type="submit" style={styles.button}>Nachricht senden</button>
      </form>

      {notification.show && (
        <div style={{
          color: notification.success ? 'green' : 'red',
          textAlign: 'center',
          marginTop: '20px'
        }}>
          {notification.message}
        </div>
      )}

      <div style={styles.contactInfo}>
        <p>E-Mail: <a href="mailto:kontakt@armann-systems.com" style={styles.link}>kontakt@armann-systems.com</a></p>
        <p>Website: <a href="https://www.armann-systems.com" style={styles.link}>www.armann-systems.com</a></p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    color: 'black',
    backgroundColor: 'white'
  },
  heading: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  input: {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc'
  },
  textarea: {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    minHeight: '100px',
  },
  button: {
    backgroundColor: 'black',
    color: 'white',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  contactInfo: {
    marginTop: '20px',
    textAlign: 'center',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  }
};

export default ContactPage;
