import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import './IssuesPage.css';
import { Helmet } from 'react-helmet';
import RelatedServices from './RelatedServices';

function IssuesPage() {
  const { dienstName } = useParams();
  const [serviceInfo, setServiceInfo] = useState(null);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [feedback, setFeedback] = useState({ show: false, message: '', success: false });
  const [timeRange, setTimeRange] = useState('24hours');

  const [commentName, setCommentName] = useState('');
  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const seoTitle = `Störungsmeldung für ${serviceInfo ? serviceInfo.Titel : dienstName}`;
  const seoDescription = `Aktuelle Informationen und Status zu Störungen bei ${serviceInfo ? serviceInfo.Titel : dienstName}. Melden Sie hier Störungen und sehen Sie vorhandene Meldungen.`;

  const handleNameChange = (event) => {
    setCommentName(event.target.value);
  };

  const handleTextChange = (event) => {
    setCommentText(event.target.value);
  };

  useEffect(() => {
    axios.get(`https://disapi.armann-systems.com/search-services`, { params: { term: dienstName }})
      .then(response => {
        if (response.data.length > 0) {
          setServiceInfo(response.data[0]);
          fetchReportData(response.data[0].DienstID, timeRange);
        } else {
          setFeedback({ show: true, message: 'Dienst nicht gefunden', success: false });
        }
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Dienstinformationen:', error);
        setFeedback({ show: true, message: 'Fehler beim Laden der Dienstinformationen', success: false });
      });
  }, [dienstName, timeRange]);

  useEffect(() => {
    if (serviceInfo && serviceInfo.Titel) {
      axios.get(`https://disapi.armann-systems.com/comments`, { params: { dienstTitel: serviceInfo.Titel, page: currentPage, limit: 10 }})
        .then(response => {
          if (response.data && response.data.comments) {
            setComments(response.data.comments);
            setTotalPages(response.data.totalPages);
          } else {
            setComments([]);
            setTotalPages(0);
          }
        })
        .catch(error => {
          console.error('Fehler beim Laden der Kommentare:', error);
        });
    }
  }, [serviceInfo, currentPage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (serviceInfo) {
        fetchReportData(serviceInfo.DienstID, timeRange);
      }
    }, 30000); // 30000 ms = 30 Sekunden
  
    return () => clearInterval(intervalId); // Bereinigen des Intervalls
  }, [serviceInfo, timeRange]); // Abhängigkeiten
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (serviceInfo && serviceInfo.Titel) {
        axios.get(`https://disapi.armann-systems.com/comments`, { params: { dienstTitel: serviceInfo.Titel, page: currentPage, limit: 10 }})
          .then(response => {
            if (response.data && response.data.comments) {
              setComments(response.data.comments);
              setTotalPages(response.data.totalPages);
            } else {
              setComments([]);
              setTotalPages(0);
            }
          })
          .catch(error => {
            console.error('Fehler beim Laden der Kommentare:', error);
          });
      }
    }, 30000); // 30000 ms = 30 Sekunden
  
    return () => clearInterval(intervalId); // Bereinigen des Intervalls
  }, [serviceInfo, currentPage]); // Abhängigkeiten

  const fetchReportData = (dienstID, range) => {
    axios.get(`https://disapi.armann-systems.com/reports`, { params: { dienstID, limitToLastHour: 'false' } })
      .then(response => {
        const transformedData = transformData(response.data, range);
        setChartData({
          labels: transformedData.labels,
          datasets: [{
            label: 'Anzahl der Störungen',
            data: transformedData.reportCounts,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        });
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Reports:', error);
      });
  };

  const submitComment = () => {
    if (!commentName || !commentText) {
      alert('Bitte füllen Sie sowohl den Namen als auch den Kommentar aus.');
      return;
    }
  
    axios.post('https://disapi.armann-systems.com/add-comment', {
      dienstTitel: serviceInfo.Titel,
      name: commentName,
      text: commentText
    })
    .then(response => {
      console.log('Kommentar erfolgreich gesendet:', response);
      setCommentName('');
      setCommentText('');
    })
    .catch(error => {
      console.error('Fehler beim Senden des Kommentars:', error);
    });
  };

  function transformData(reports, range) {
    let intervals, intervalLengthInHours;
    if (range === '7days') {
      intervals = 7 * 8;
      intervalLengthInHours = 3;
    } else {
      intervals = 24;
      intervalLengthInHours = 1;
    }

    const now = new Date();
    const reportCounts = Array(intervals).fill(0);
    const labels = Array(intervals).fill('');

    for (let i = 0; i < intervals; i++) {
      const intervalDate = new Date(now.getTime() - (intervals - i - 1) * intervalLengthInHours * 60 * 60 * 1000);
      labels[i] = `${intervalDate.getDate()}/${intervalDate.getMonth() + 1} ${intervalDate.getHours()}:00`;
    }

    reports.forEach(report => {
      const reportDate = new Date(report.Timestamp);
      const diffHours = Math.floor((now - reportDate) / (1000 * 60 * 60));
      const index = intervals - Math.floor(diffHours / intervalLengthInHours) - 1;
      if (index >= 0 && index < intervals) {
        reportCounts[index]++;
      }
    });

    return { labels, reportCounts };
  }

  const goToNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const reportIssue = () => {
    axios.post(`https://disapi.armann-systems.com/report`, { dienstTitel: dienstName })
      .then(response => {
        setFeedback({ show: true, message: 'Störung erfolgreich gemeldet', success: true });
      })
      .catch(error => {
        setFeedback({ show: true, message: error.response.data, success: false });
      });
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(200, 200, 200, 0.3)', // Subtle grid lines
        },
        ticks: {
          stepSize: 1,
          font: {
            size: 14,
            family: 'Arial, sans-serif',
            weight: '500',
          },
          color: '#666',
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Arial, sans-serif',
            weight: '500',
          },
          color: '#666',
          maxRotation: 45, // Tilted labels
          minRotation: 45,
        },
      },
    },
    plugins: {
      legend: {
        display: true, // Hide the legend for a cleaner look
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleFont: {
          size: 16,
          family: 'Arial, sans-serif',
          weight: '600',
        },
        bodyFont: {
          size: 14,
          family: 'Arial, sans-serif',
        },
        padding: 12,
        cornerRadius: 8,
        caretSize: 8,
      },
      title: {
        display: true,
        text: timeRange === "24hours" ? 'Störungsberichte in den letzten 24 Stunden' : 'Störungsberichte in den letzten 7 Tagen',
        font: {
          size: 22,
          family: 'Arial, sans-serif',
          weight: '700',
        },
        color: '#333',
      },
    },
    elements: {
      line: {
        tension: 0.4, // Smooth the line curve
        borderWidth: 2,
        borderColor: 'rgba(100, 100, 100, 0.8)', // Muted line color
        backgroundColor: 'rgba(100, 100, 100, 0.1)', // Light gradient fill
        fill: true, // Enable the fill under the line
        borderCapStyle: 'round', // Rounded edges for the line
        shadowOffsetX: 2,
        shadowOffsetY: 2,
        shadowBlur: 8,
        shadowColor: 'rgba(0, 0, 0, 0.2)', // Shadow effect for depth
      },
      point: {
        radius: 2, // Smaller points
        hoverRadius: 4,
        backgroundColor: '#fff',
        borderColor: 'rgba(100, 100, 100, 0.8)',
        borderWidth: 2,
        hoverBorderWidth: 2,
      },
    },
  };
  
  
  

  function generateCommentSchema(comment) {
    return {
        "@context": "http://schema.org",
        "@type": "Comment",
        "author": {
            "@type": "Person",
            "name": comment.Name
        },
        "datePublished": new Date(comment.Timestamp).toISOString(),
        "text": comment.Text
    };
}


  return (
    <div className="issues-container">
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <link rel="canonical" href={`https://xn--strung-xxa.armann-systems.com/services/${dienstName}`} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Service",
            "name": serviceInfo?.Titel,
            "description": serviceInfo?.description,
          })}
        </script>
      </Helmet>
      <div className="sidebar left-sidebar">
        <RelatedServices />
      </div>
      <div className="main-content">
        <header className="issues-header">
          <img 
            src={`https://img.ashub.de/services/${serviceInfo ? serviceInfo.DienstName : ''}.png`} 
            alt={`${serviceInfo ? serviceInfo.Titel : dienstName} Logo`} 
            className="service-logo" 
            loading="lazy"
            onError={(e) => { e.target.onerror = null; e.target.src = 'https://img.ashub.de/services/404.png'; }}
          />
          <h1 className="service-title">{serviceInfo ? serviceInfo.Titel : dienstName}</h1>
        </header>
        <p className="service-description">
          {serviceInfo ? serviceInfo.description : "Lade Dienstbeschreibung..."}
        </p>
        <button className="report-issue-button" onClick={reportIssue}>
          Störung melden
        </button>
        <button 
  className="go-to-comments" 
  onClick={() => {
    const commentsSection = document.querySelector('.comments-section');
    if (commentsSection) {
      commentsSection.scrollIntoView({ behavior: 'smooth' });
    }
  }}
>
  <span>Zu den Kommentaren</span>
</button>

        {feedback.show && (
          <div className={`feedback-alert ${feedback.success ? 'success' : 'error'}`}>
            {feedback.message}
          </div>
        )}
        <div className="chart-controls">
          <button onClick={() => setTimeRange('7days')} disabled={timeRange === '7days'}>
            Letzte 7 Tage
          </button>
          <button onClick={() => setTimeRange('24hours')} disabled={timeRange === '24hours'}>
            Letzte 24 Stunden
          </button>
        </div>
        
        <div className="chart-large">
          <Line data={chartData} options={chartOptions} />
        </div>
        <div className="comments-section">
          <h2>Kommentare</h2>
          <div className="comment-form">
            <input 
              type="text" 
              placeholder="Ihr Name" 
              className="comment-name-input"
              value={commentName}
              onChange={handleNameChange}
            />
            <textarea 
              placeholder="Ihr Kommentar" 
              className="comment-text-input"
              value={commentText}
              onChange={handleTextChange}
            />
            <button className="submit-comment-button" onClick={submitComment}>Kommentar absenden</button>
          </div>
          <div className="comment-list">
            {comments.map((comment, index) => (
              <div key={index} className="comment">
                <script type="application/ld+json">
                  {JSON.stringify(generateCommentSchema(comment))}
                </script>
                <div className="comment-header">
                  {comment.Name} - {new Date(comment.Timestamp).toLocaleString()}
                </div>
                <div className="comment-text">
                  {comment.Text}
                </div>
              </div>
            ))}
          </div>
          {totalPages > 1 && (
            <div className="comment-pagination">
              <button onClick={goToPreviousPage} disabled={currentPage === 1}>Vorherige Seite</button>
              <span>{currentPage}</span>
              <button onClick={goToNextPage} disabled={currentPage === totalPages}>Nächste Seite</button>
            </div>
          )}
        </div>
      </div>
      <div className="sidebar right-sidebar">
        <RelatedServices />
      </div>
    </div>
  );
}

export default IssuesPage;
